import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';
import { compose } from 'lodash.flowright';


// const composeEnhancer = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : null || compose;
// console.log(process.env);
// export const store = createStore(
//   rootReducer,
//   composeEnhancer(applyMiddleware(thunkMiddleware)),
// );


export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
    )
);
