import { combineReducers } from 'redux';

import { user_data } from './user';
import { products } from './product';


const rootReducer = combineReducers({
  user_data,
  products,
});

export default rootReducer;
