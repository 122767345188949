import { userConstants } from '../constants';


const initialState = {
    loggedIn:false,
    user: null,
    auth: null,
};

export function user_data(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: false,
        auth: action.auth
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        auth: action.auth
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    case userConstants.ME_REQUEST:
      console.log(action)
      return {
        ...state,
        user: action.user
      };
    case userConstants.ME_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user
      };
    case userConstants.ME_FAILURE:
      return {
        initialState
      };
    default:
      return state
  }
}
