import axios from 'axios';

export const userService = {
    login,
    logout,
    me

};


function logout() {
  console.log('LOGOUT');
    // remove user from local storage to log user out
    localStorage.removeItem('access_token');
    localStorage.removeItem('expire_in');
    localStorage.removeItem('refresh_token');
}

function login(username, password) {


    var formBody = new FormData();
    formBody.set("grant_type","password");
    formBody.set("client_id", process.env.REACT_APP_CLIENT_ID);
    formBody.set("client_secret",process.env.REACT_APP_CLIENT_SECRET);
    formBody.set("username", username);
    formBody.set("password", password);
    formBody.set("scope", "*");



    const requestOptions = {
        method: 'POST',
        headers: { 'Access-Control-Allow-Origin': '*'},
        body: formBody
    };

    return fetch(process.env.REACT_APP_LOGIN_ENDPOINT, requestOptions)
        .then(response => {
          return response.text().then(text => {
              const data = text && JSON.parse(text);
              if (!response.ok) {
                  if (response.status === 401) {
                      // auto logout if 401 response returned from api
                      logout();
                      //location.reload(true);
                  }

                  const error = (data && data.message) || response.statusText;
                  return Promise.reject(error);
              }

              localStorage.setItem('access_token', data.access_token);
              localStorage.setItem('expire_in', data.expires_in);
              localStorage.setItem('refresh_token', data.refresh_token);
              return data;
          });
        })

}


function me(access_token) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Access-Control-Allow-Origin': '*' ,'Authorization':'Bearer '+access_token},
    };

    return fetch(process.env.REACT_APP_ME_ENDPOINT, requestOptions)
        .then(handleResponse)
        .catch(error =>{
            logout();
            return Promise.reject("error");
        });

}

function handleResponse(response) {

    if(response.status === 401 ||  response.status === 500){
        console.log('ERRORE')
        logout();
        return Promise.reject("error");
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            // if (response.status === 401) {
            //     // auto logout if 401 response returned from api
            //     logout();
            //     //location.reload(true);
            // }

            logout();
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
