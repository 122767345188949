import React, { Component } from 'react';
import { HashRouter, Route, Switch,Router,Redirect } from 'react-router-dom';
import { history } from './cloud/helpers';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { connect } from 'react-redux';
import  Dashboard  from './cloud/Pages/Dashboard';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./cloud/Pages/Login'));
//const Dashboard = React.lazy(() => import('./cloud/Pages/Dashboard'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {


  render() {
    console.log('render route');
    console.log(this.props.user_data.loggedIn);
    const ProtectedRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
          this.props.user_data.loggedIn === true
          ? <Component {...props} />
          : <Redirect exact to={{
              pathname: '/Login',
              state: { from: props.location }
            }} />
      )} />
    );

    return (
      // <HashRouter>
      //     <React.Suspense fallback={loading()}>
      //       <Switch>
      //         <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
      //         <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
      //         <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
      //         <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
      //         <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
      //       </Switch>
      //     </React.Suspense>
      // </HashRouter>

      <Router history={history}>
        <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
              <Route exact path="/dashboard" name="Dashboard Page" render={props => <Dashboard {...props}/>} />
            </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

const mapStateToProps = state => {
    return {
        user_data: state.user_data,
    };
};


export default connect(mapStateToProps)(App);
