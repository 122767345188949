import { productConstants } from '../constants';
import { porductService } from '../services';

export const productActions = {
    getAll,
};

function getAll() {
    return dispatch => {
        dispatch(request());

        porductService.products()
            .then(
                product => {
                    dispatch(success(product));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(products) { return { type: productConstants.PRODUCT_REQUEST, products } }
    function success(products) { return { type: productConstants.PRODUCT_SUCCESS, products } }
    function failure(error) { return { type: productConstants.PRODUCT_FAILURE, error } }
}
