export const userConstants = {

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',


    ME_REQUEST: 'ME_LOGIN_REQUEST',
    ME_SUCCESS: 'ME_LOGIN_SUCCESS',
    ME_FAILURE: 'ME_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

};
