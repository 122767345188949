import { productConstants } from '../constants';


const initialState = {
    data: [],
    loading: true,
    error: false,
};

export function products(state = initialState, action) {
  switch (action.type) {
    case productConstants.PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
      };
    case productConstants.PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.products,
      };
    case productConstants.PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
      };
    default:
      return state
  }
}
