export const porductService = {
    products,

};


function products() {

    const access_token = localStorage.getItem('access_token');

    const requestOptions = {
        method: 'GET',
        headers: { 'Access-Control-Allow-Origin': '*' ,'Authorization':'Bearer '+access_token},
    };

    return fetch(process.env.REACT_APP_ADMIN_ENDPOINT+"products", requestOptions)
        .then(handleResponse)
        .then(response => {

            return  response;

        });

}

function handleResponse(response) {

    if(response.status === 401){
        console.log('ERRORE')
        return Promise.reject("error");
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
