import React, { Component } from 'react';

import {
  Card,
  CardBody,
  Col,
} from 'reactstrap';

class ServiceCard extends Component {

  constructor(props) {
    super(props);
    this.defaultColor = "#00ff77";
  }







  render(){
    const cardStyle = {
        backgroundColor: this.props.color,
        borderColor: this.props.color,

    };

    const accessToken = localStorage.getItem('access_token');

    return(
      <Col xs="12" sm="6" lg="4">
        <a href={this.props.url+`?access_token=`+accessToken} target="_blank" style={{ textDecoration: 'none' }}>
        <Card className="text-white" key={this.props.slug} style={cardStyle}>
          <CardBody className="pb-10" >
            <div className="text-value">{this.props.name}  <i className={this.props.icon} aria-hidden="true"></i></div>
            <p><b>{this.props.description}</b></p>
          </CardBody>
        </Card>
        </a>
      </Col>
    )
  }


}

export default ServiceCard
