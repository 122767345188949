import React, { Component, lazy } from 'react';
import {
  Row,
} from 'reactstrap';

import { productActions } from '../actions'
import { connect } from 'react-redux';
import  ServiceCard from '../components/ServiceCard';


const Widget03 = lazy(() => import('../../views/Widgets/Widget03'));



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.props.products();
    this.toggle = this.toggle.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

    this.state = {
      dropdownOpen: false,
      radioSelected: 2,
      user:null,
      products: []
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onRadioBtnClick(radioSelected) {
    this.setState({
      radioSelected: radioSelected,
    });
  }


  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  render() {
  let loader = <div></div>
  let products = (
          <div>
              <p></p>
          </div>
      );
  if(this.props.productList.loading === false && this.props.productList.error === false){
    products =   this.props.productList.data.data.map((product) =>
         <ServiceCard
            key= {product.attributes.slug}
            name= {product.attributes.name}
            description= {product.attributes.description}
            color= {product.attributes.color}
            url = {product.attributes.url}
            icon = {product.attributes.icon}
         />

      );

  }else{
    let products = (
            <div>
                <p>Nessun prodotto disponibile</p>
            </div>
        );
  }

  if(this.props.productList.loading === true){
    loader =  (
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
    )
  }



    return (
    <div>
      {loader}
      <div>
        <Row>
          {products}
        </Row>
      </div>
    </div>

    );
  }
}


const mapStateToProps = state => ({
    user_data: state.user_data,
    productList: state.products

})

const mapDispatchToProps = dispatch => {
    return {
        products: () => dispatch(productActions.getAll())
    };
};


export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);
