import { userConstants } from '../constants';
import { userService } from '../services';
import { history } from '../helpers';

export const userActions = {
    login,
    logout,
    me,
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                auth => {
                  console.log()
                    dispatch(success(auth));
                    dispatch(me(auth.access_token))
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request(auth) { return { type: userConstants.LOGIN_REQUEST, auth } }
    function success(auth) { return { type: userConstants.LOGIN_SUCCESS, auth } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function me(access_token) {
    return dispatch => {
        dispatch(request({ access_token }));

        userService.me(access_token)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/dashboard');
                },
                error => {
                    dispatch(failure(error.toString()));
                    history.push('/');
                }
            );
    };

    function request(user) { return { type: userConstants.ME_REQUEST, user } }
    function success(user) { return { type: userConstants.ME_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ME_FAILURE, error } }
}

function logout() {
    userService.logout();
    history.push('/');
    return { type: userConstants.LOGOUT };
}
